.RoomPage__navbar_background {
    height: 60px;
}

.RoomPage__navbar {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #efefef;
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    border-top: 1px black solid;
    text-align: center;
    box-sizing: border-box;
}