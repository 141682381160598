.Members {
    padding: 0 20px;
}

.Members__copy_field {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: #efefef;
    border-radius: 2px;
    padding: 8px;
    user-select: all;
}