.Roles {
    padding: 0 20px;
}

.Roles__team {
    margin-bottom: 5px;
}

.Roles__role {
    margin-bottom: 10px;
}

.RoleItem {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 10px;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    color: white;
}

.RoleItem__action {
    height: 20px;
    width: 20px;
    cursor: pointer;
}